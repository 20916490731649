<template>
    <pop-view>
        <div class="weak-password-alert">
            <div class="weak-password-alert-top">
                <img class="weak-password-alert-top-icon" :src="alertIcon">
                <p class="weak-password-alert-top-title">安全提示</p>
            </div>
            <div class="weak-password-alert-content">您仍在使用弱密码，为了您的数据安全，请尽快修改密码！</div>
            <button class="weak-password-alert-confirm" @click="onConfirmClick">确定</button>
        </div>
    </pop-view>
</template>
  
<script>
import PopView from "@c/common/pop-view/pop-view.vue";
export default {
    components: {
        PopView,
    },

    model: {
        prop: "isShow",
        event: "onIsShowChanged"
    },

    props: {
        isShow: Boolean
    },

    data() {
        return {
            alertIcon: require("@imgs/main-weak-password-alert.png"),
        };
    },

    methods: {
        onConfirmClick() {
            this.$emit("onIsShowChanged", false)
            this.$emit("onConfirmClick")
        }
    },
};
</script>
  
<style lang="scss" scoped>
.weak-password-alert {
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &-top {
        width: 100%;
        height: 60px;
        border: 1px solid rgba($color: #979797, $alpha: 0.16);
        display: flex;
        align-items: center;

        &-icon {
            width: 21px;
            height: 22px;
            margin-left: 30px;
        }

        &-title {
            color: rgba($color: #000000, $alpha: 0.9);
            font-size: 16px;
            margin-left: 11px;
        }
    }

    &-content {
        color: rgba($color: #000000, $alpha: 0.9);
        font-size: 16px;
        line-height: 22px;
        width: 339px;
        margin-top: 25px;
    }

    &-confirm {
        background-color: #2779FF;
        color: white;
        width: 60px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        align-self: flex-end;
        margin-right: 31px;
        margin-top: 40px;
        margin-bottom: 20px;
    }


}
</style>